.highlight select {
  animation-name: highlight-select;
  animation-duration: 2s;
}

.highlight label {
  animation-name: highlight-label;
  animation-duration: 2s;
}

.highlight label::after {
  background-color: transparent !important;
}

@keyframes highlight-select {
  20% {
    color: red;
    border-color: red;
    background-color: #FFCCCC;
  }
}

@keyframes highlight-label {
  20% {
    color: red;
  }
}
